<template>
  <v-card class="teros-elevation">
    <v-card-text>
      <v-row>
        <v-col cols="2">
          <v-select
            hide-details
            outlined
            dense
            v-model="method"
            label="Método"
            :items="['Get', 'Save', 'Delete', 'Patch']"
          ></v-select>
        </v-col>
        <v-col cols="7">
          <v-text-field
            hide-details
            outlined
            dense
            v-model="endpoint"
            label="API Endpoint"
            placeholder="/v1/clientes"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            hide-details
            outlined
            dense
            v-model="params"
            label="ID"
            placeholder='ex.: 1 ou { "id": 5, "query": "tipo=3"}'
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-textarea
            hide-details
            outlined
            dense
            v-model="body"
            label="Body"
            placeholder='ex.: { "nome": "Fulano de Tal" }'
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn @click="execute()">Executar</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          Resultado
          <v-lazy
            v-model="isActive"
            :options="{
              threshold: .5
            }"
            min-height="200"
            transition="fade-transition"
          >
            <pre v-html="result"></pre>
          </v-lazy>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data: function () {
    return {
      isActive:false,
      method: "Get",
      endpoint: "",
      params: null,
      body: "",
      result: "",
    };
  },
  methods: {
    execute() {
      var resource = this.apiResource(this.endpoint);
      var methods = {
        Get: {
          method: resource.get,
          p1: this.params && this.params != "" ? JSON.parse(this.params) : null,
        },
        Save: {
          method: resource.save,
          p1: this.body && this.body != "" ? JSON.parse(this.params) : null,
          p2: this.params && this.params != "" ? JSON.parse(this.params) : null,
        },
        Delete: {
          method: resource.delete,
          p1: this.params && this.params != "" ? JSON.parse(this.params) : null,
        },
        Patch: {
          method: resource.delete,
          p1: this.body && this.body != "" ? JSON.parse(this.params) : null,
          p2: this.params && this.params != "" ? JSON.parse(this.params) : null,
        },
      };
      
      var toExecute = methods[this.method];
      toExecute.method(toExecute.p1, toExecute.p2).then((response) => {
        this.result = this.syntaxHighlight(response);
      });
    },
    syntaxHighlight(obj) {
      var json = JSON.stringify(obj, undefined, 2);
      json = json
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;");
      return json.replace(
        /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g,
        function (match) {
          var cls = "number";
          if (/^"/.test(match)) {
            if (/:$/.test(match)) {
              cls = "key";
            } else {
              cls = "string";
            }
          } else if (/true|false/.test(match)) {
            cls = "boolean";
          } else if (/null/.test(match)) {
            cls = "null";
          }
          return '<span class="' + cls + '">' + match + "</span>";
        }
      );
    },
  },
};
</script>

<style>
pre {
  outline: 1px solid #ccc;
  padding: 5px;
  margin: 5px;
  font-size: 12px;
}
.string {
  color: green;
}
.number {
  color: darkorange;
}
.boolean {
  color: blue;
}
.null {
  color: magenta;
}
.key {
  color: red;
}
</style>